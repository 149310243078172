.packaging-type-manage .text-select{
    color: rgb(67, 105, 233);
}

.packaging-type-manage .text-select:hover{
    text-decoration: underline;
    cursor: pointer;
}

.packaging-type-manage .ant-table-content{
    height: calc( 100vh - 30rem) !important;
    overflow-y: auto !important;
    position: relative;
}

/* .packaging-type-manage button.ant-switch:not(.ant-switch-disabled) {
    background: rgba(0, 0, 0, 0.45);
} */

.modal-packaging .text-select{
    color: rgb(67, 105, 233);
}

.modal-packaging .text-select:hover{
    text-decoration: underline;
    cursor: pointer;
}

.modal-packaging .ant-table-content{
    height: calc( 100vh - 30rem) !important;
    overflow-y: auto !important;
    position: relative;
}