.items-manage .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
}

.items-access .ant-table-tbody > tr > td, 
.items-access .ant-table-thead > tr > th { 
    white-space: break-spaces;
    vertical-align: top;
}

.items-access .ant-collapse>.ant-collapse-item:last-child, 
.items-access .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 12px;
    background-color: #d9d9d9;
    border: none;
}

.items-access .ant-collapse .ant-collapse-header,
.items-access .ant-collapse .ant-collapse-items-active .ant-collapse-header { 
    border-bottom: 0px solid #fff;
    padding-inline-start: 12px;
    background-color: #d9d9d9;
}


.items-access .ant-collapse .ant-collapse-items-active .ant-collapse-content,
.items-access .ant-collapse .ant-collapse-content {
    color: rgba(0, 0, 0, 0.88);
    background-color: #d9d9d9;
    border-top: 0px solid #d9d9d9;
    padding-inline: 12px;
}

.ant-form .ant-form-item {
    margin-bottom: 6px !important;
}
td.ant-table-cell>.ant-form-item {
    margin-bottom: 0px !important;
}
span.ant-typography+.ant-form-item.\!m-0,
h3.ant-typography+.ant-form-item.\!m-0, 
h4.ant-typography+.ant-form-item.\!m-0 
{
    margin-bottom: 0px !important;
}
  
.items-manage .ant-table-wrapper .ant-table-thead >tr>th, 
.items-manage .ant-table-wrapper .ant-table-thead >tr>td {
    vertical-align: middle;
}

.items-manage .text-end .ant-input-number-input {
    text-align: right;
}

/* .items-manage .ant-drawer-content-wrapper {
    transform: translateX(0px) !important;
}  */

@media screen and (max-width: 768px) {
  .items-manage .ant-drawer-content-wrapper {
     width: 100vw !important; /* Adjust the width for smaller screens */
   } 
}

@media screen and (min-width: 768px) {
  .items-manage .ant-drawer-content-wrapper {
      width: 40vw !important; /* Set a fixed width for larger screens */
    } 
}

.radio-group-student-gender {
    display: flex;
    align-items: center;
    height: 100%;
}
  
.radio-group-student-gender .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}